import Error from '../components/Error';

import ScrollToTop from '../components/scroll/ScrollToTop';

export default function Error404() {

	return (

		<>
			<Error/>
			<ScrollToTop/>
		</>
	)
}