import House from '../components/House';

import ScrollToTop from '../components/scroll/ScrollToTop';

export default function Housing() {
	return (

		<>
			<House/>
			<ScrollToTop/>
		</>
	)
}