import About from '../components/About';

import ScrollToTop from '../components/scroll/ScrollToTop';

export default function AboutUs() {

	return (

		<>
			<About/>
			<ScrollToTop/>
		</>
	)
}